import React, { useState, useEffect, useContext } from 'react'
import { publicFetch } from '../util/fetcher'
import { coinslist } from '../config/config'

export default function TradingPairs({ onSelectPair }) {
  const [selectedCoin, setSelectedCoin] = useState('BTC')
  const [price, setPrice] = useState({})

  const handleClickPair = (coin) => {
    setSelectedCoin(coin)
    onSelectPair(coin)
  }

  const getPrice = async () => {
    const { data } = await publicFetch.post('get-price', {});
    setPrice(data)
  };

  useEffect(() => {
    setInterval(() => {
      getPrice();
    }, 3000)
  }, [])


  return (
    <>
      <div className="trading-pair mb15">
        <div className="row">
          <div className="col-12">
            <div className="row">
              {coinslist.map((coin, index) => <>
                <div className={`col-3 col-md-2 col-lg-1 pair ${selectedCoin == coin ? 'active' : ''}`} onClick={() => handleClickPair(coin)}>
                  <h6>{coin}/USDT</h6>
                  <span className='stats-type'>{price[coin]} USD</span>
                </div>
              </>)}
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
