import React, { useState, useEffect } from 'react'
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import axios from 'axios';

export default function Swap(props) {
  const [coins, setCoins] = useState([])
  const [coin_send, setCoinSend] = useState('')
  const [coin_receive, setCoinReceive] = useState('')
  const [rate, setRate] = useState(0)
  const [rate_type, setRateType] = useState('dynamic')
  const [amount_send, setAmountSend] = useState(0.01)
  const [amount_receive, setAmountReceive] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [send_minimum, setSendMinimum] = useState(0)
  const [receive_maximum, setReceiveMaximum] = useState(0)
  const [network_fee, setNetworkFee] = useState(0)
  const [arrival_minute, setArrivalMinute] = useState(0)
  const [recipient, setRecipient] = useState('')
  const [recipient_validated, setRecipientValidated] = useState(false)
  const [memo_needed, setMemoNeeded] = useState(false)
  const [memo, setMemo] = useState('')
  const [agreed, setAgreed] = useState(false)
  const [step, setStep] = useState(1)

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  //mounted:
  useEffect(() => {
    setCoinSend(query.get("coin_send") || 'BTC');
    setCoinReceive(query.get("coin_receive") || 'ETH');
    setAmountSend(query.get("amount_send") || 0.01);

    axios.post(`${process.env.REACT_APP_SWAP_API}/get_coins`, {})
      .then(res => {
        if (res.data.result) {
          var coins = res.data.data;
          setCoins(coins);
          setLoaded(true);
        }
      })
  }, []);

  // Change rate
  useEffect(() => {
    if (coin_send && coin_receive) {
      setLoaded(false);
      axios.post(`${process.env.REACT_APP_SWAP_API}/get_full_rate`, { coin_send, coin_receive, type: rate_type })
        .then(res => {
          setRate(res.data.data);
          setLoaded(true);
        })
    }
  }, [coin_send, coin_receive, rate_type]);
  //Change amount_send
  useEffect(() => {
    setAmountReceive(Math.floor(amount_send * rate * 100000000) / 100000000)
  }, [amount_send, rate]);
  //Change min, max, network_fee, arrival_minute, memo_needed
  useEffect(() => {
    for (var x in coins) {
      if (coins[x]['code'] === coin_send) {
        setSendMinimum(Math.ceil(coins[x]['minimum'] * 100000) / 100000)
        setArrivalMinute(coins[x]['arrival_minute'])
      }
      if (coins[x]['code'] === coin_receive) {
        setReceiveMaximum(Math.floor(coins[x]['maximum'] * 100000) / 100000)
        setNetworkFee(coins[x]['network_fee'])
        setMemoNeeded(coins[x]['memo'])
      }
    }
  }, [coin_send, coin_receive, coins]);
  //Coin change button
  function changeCoin() {
    var temp = coin_send;
    setCoinSend(coin_receive);
    setCoinReceive(temp);
  }
  //Recipient Validated
  useEffect(() => {
    setRecipientValidated(false);
    axios.post(`${process.env.REACT_APP_SWAP_API}/validate_address`, { coin: coin_receive, address: recipient })
      .then(res => {
        setRecipientValidated(res.data.result);
      })
  }, [coin_receive, recipient]);
  //make order
  function makeOrder() {
    axios.post(`${process.env.REACT_APP_SWAP_API}/make_order`, { coin_send, coin_receive, amount_send, rate_type, recipient, memo })
      .then(res => {
        if (res.data.result)
          window.location.href = "/swap/send?order_id=" + res.data.data.order_id;
        else
          alert(res.data.message);
      })
  }


  return (
    <>
      <div className="card">
        <div className="card-body">
          {
            step === 1 ?
              <>
                <div className="swap-choose">
                  <ButtonGroup className="mb-3 p-2 btn-rate-group">
                    <Button className={rate_type === 'dynamic' ? "btn-rate active" : "btn-rate"} onClick={() => { setRateType('dynamic') }} variant="">Dynamic Rate</Button>
                    <Button className={rate_type === 'fixed' ? "btn-rate active" : "btn-rate"} onClick={() => { setRateType('fixed') }} variant="">Fixed Rate</Button>
                  </ButtonGroup>
                  <Row>
                    <Col md={2} className="text-center">
                      <img src={`https://tradingpanelaccount.org/medium/${coin_send}.png`} width="64px" height="64px" className="img-circle cryptoexchanger-icon2" alt="BTC" />
                    </Col>
                    <Col md={7}>
                      <div className="form-group">
                        <label>You send</label>
                        <select className="custom-select" onChange={(e) => { setCoinSend(e.target.value) }}>
                          {
                            coins.map((coin, i) =>
                              <option key={i} value={coin.code} disabled={coin.code === coin_receive} selected={coin.code === coin_send}>
                                {coin.name} {coin.code}
                              </option>
                            )}
                        </select>
                      </div>
                      {
                        (send_minimum > amount_send) ?
                          <p className="notify_limitamount">Minimum send amount: {send_minimum} {coin_send}</p> : <></>
                      }
                    </Col>
                    <Col md={3}>
                      <div className="form-group">
                        <label>Amount</label>
                        <input type="number" className="form-control" step="0.00000001" value={amount_send} onChange={(e) => { setAmountSend(e.target.value) }} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={10} xs={10} className="pl-5">
                      <span>
                        <i className="fa fa-lock mr-3"></i>
                        <span>1 {coin_send} ~ {rate} {coin_receive}</span>
                      </span>
                    </Col>
                    <Col lg={2} xs={2}>
                      <button className="btn btn-light switch-button pull-right" onClick={() => { changeCoin() }}>
                        <i className="fa fa-refresh"></i>
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} xs={2} className="text-center">
                      <img src={`https://tradingpanelaccount.org/medium/${coin_receive}.png`} width="64px" height="64px" className="img-circle cryptoexchanger-icon2" alt="ETH" />
                    </Col>
                    <Col md={7} xs={7}>
                      <div className="form-group">
                        <label>You get approximately</label>
                        <select className="custom-select" onChange={(e) => { setCoinReceive(e.target.value) }}>
                          {
                            coins.map((coin, i) =>
                              <option key={i} value={coin.code} disabled={coin.code === coin_send} selected={coin.code === coin_receive}>
                                {coin.name} {coin.code}
                              </option>)}
                        </select>
                      </div>
                      {
                        (amount_receive > receive_maximum) ?
                          <p className="notify_limitamount">Maximum receive amount: {receive_maximum} {coin_receive}</p> : <></>
                      }
                    </Col>
                    <div className="col-md-3 col-xs-3">
                      <div className="form-group">
                        <label>Amount</label>
                        <input type="number" className="form-control" value={amount_receive} readOnly="readonly" />
                      </div>
                    </div>
                  </Row>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Please enter your 12 wallet address</label>
                        <input type="text" name="recipient" className="form-control" value={recipient} onChange={(e) => { setRecipient(e.target.value) }} />
                        {recipient_validated ?
                          <></> :
                          <small style={{ color: 'palevioletred' }}>Please enter a valid address</small>
                        }
                      </div>
                    </div>
                  </div>
                  {memo_needed ?
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Please enter memo/tag (optional)</label>
                          <input type="text" name="memo" className="form-control" value={memo} onChange={(e) => { setMemo(e.target.value) }} />
                        </div>
                      </div>
                    </div>
                    : <></>
                  }

                  <hr />
                  <div className="row">
                    <div className="col-12">
                      {loaded ?
                        rate ? <>
                          <>
                            <button className="btn btn-info btn-block w-50 mx-auto"
                              disabled={!recipient_validated || amount_receive > receive_maximum || send_minimum > amount_send}
                              onClick={() => { setStep(2) }}>
                              Confirm
                            </button>
                          </>
                        </>
                          :
                          <>
                            <div className='text-center'>
                              <h5>Can not trade this pair for now!</h5>
                            </div>
                          </>
                        :
                        <>
                          <button className="btn btn-info btn-block w-50 mx-auto" disabled>
                            Loading
                          </button>
                        </>}
                    </div>
                  </div>
                </div>
              </> : <>
                <img src={`https://tradingpanelaccount.org/medium/${coin_send}.png`}
                  width="64px" height="64px" className="img-circle cryptoexchanger-icon2" alt="coin send" />
                <h3>You send {amount_send} {coin_send}</h3>
                <img src={`https://tradingpanelaccount.org/medium/${coin_receive}.png`}
                  width="64px" height="64px" className="img-circle cryptoexchanger-icon2" alt="coin send" />
                <h3>You get {amount_receive} {coin_receive}</h3>
                <h3>Rate: 1 {coin_send} ~ {rate} {coin_receive}</h3>
                <h3>Networkfee: {network_fee} {coin_receive}</h3>
                <h3>Your wallet address: {recipient}</h3>
                <h3>Estimated Arrival: {arrival_minute} minutes</h3>
                <div class="form-group">
                  <input type="checkbox" checked={agreed} onChange={(e) => { setAgreed(e.target.value) }} />
                  <label>
                    I acknowledge that I have read and agree to the
                    <a href="https://tradingpanelaccount.org/terms-and-conditions/">Terms and Conditions
                    </a>
                    and
                    <a href="https://tradingpanelaccount.org/privacy-policy">Privacy Policy.</a></label><br />
                </div>
                <Button onClick={() => { makeOrder() }}>Confirm</Button>
                <Button onClick={() => { setStep(1) }}>Back</Button>
              </>
          }
        </div>
      </div>
    </>
  );
}
