import React, { useState, useEffect, useContext } from 'react'
import { Grid, Modal, Button, Box, Typography, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRCode from "react-qr-code";

import { publicFetch } from '../util/fetcher.js';
import { AuthContext } from '../store/auth.js';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { WITHDRAW_CONFIG } from '../config/config.js';


export default function Wallets() {
  // deposit
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({
    address: '0x12345',
    code: 'XYZ'
  })

  // withdraw
  const [showModal2, setShowModal2] = useState(false)
  const [modalData2, setModalData2] = useState({
    code: 'BTC',
    balance: 1.00,
    address: '', //user wallet
    amount: 0, //withdraw amount
  });


  let userInfo = localStorage.getItem('userInfo');
  let username, user_id, token;
  if (userInfo) {
    username = JSON.parse(userInfo)?.username;
    user_id = JSON.parse(userInfo)?.id;
    token = localStorage.getItem('token');
  }

  const [walletInfo, setWalletInfo] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let total = 0;
    for (const coin in walletInfo) {
      total += walletInfo[coin].balance * walletInfo[coin].price
    }
    setTotal(total)
  }, [walletInfo])


  useEffect(() => {
    //wallet
    const getWallet = async (name) => {
      const { data } = await publicFetch.post('get-wallet', { user_id });
      let response = data;
      console.log('getWallet', response);
      setWalletInfo(response.data)
      console.log(Object.keys(response.data))
    };
    getWallet(username);
  }, []);

  const onClickWithdrawConfirm = async () => {
    const { data } = await publicFetch.post('withdraw', {
      username,
      token,
      code: modalData2.code,
      address: modalData2.address,
      amount: modalData2.amount
    });
    NotificationManager.success('Your withdrwal request has been submitted succesfully!');
    setShowModal2(false)
  }

  const depositModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };
  const withdrawModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    padding: '50px'
  };

  const onClickCopy = (text) => {
    navigator.clipboard.writeText(text);
  }

  const WallletItem = ({ code, name, balance, price, address, network }) => {
    const handleClickDeposit = () => {
      setModalData(
        {
          address: address,
          code: code,
          network: network
        }
      );
      setShowModal(true);
    }

    const handleClickWithdraw = () => {
      setModalData2(
        {
          code: code,
          balance: balance,
          network: network,
          amount: 0
        }
      );
      setShowModal2(true);
    }

    return <>
      <div className="market-carousel-item" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
        <div className="market-carousel-item-name">
          <img src={`img/cryptos/${code}.png`} alt="" />
          <strong>{name}</strong>
        </div>
        <h6>Address:</h6>
        <p style={{ fontSize: '15px' }}>{address}  <span style={{ cursor: 'pointer' }} onClick={() => onClickCopy(address)}><ContentCopyIcon /></span></p>
        <h3>{Number(balance)?.toFixed(4)} {code} <span style={{ fontSize: '21px', color: 'gray' }}>≈ {Number(price * balance).toFixed(3)} USD</span></h3>
        <p>(1 {code} = {price} USDT)</p>
        <button className="btn buy" onClick={handleClickDeposit}>Deposit</button>
        <button className="btn sell" onClick={handleClickWithdraw}>Withdraw</button>
      </div>
    </>;
  }

  return (
    <>
      <div>
        <h3 className='totalasset'>Total Assets: {total?.toFixed(3)} USDT</h3>
        <Grid container spacing={2}>
          {
            Object.keys(walletInfo).map((coin, index) =>
              <Grid item xs={12} md={6} lg={4} key={index}>
                <WallletItem
                  code={coin}
                  name={walletInfo[coin].name}
                  balance={walletInfo[coin].balance}
                  price={walletInfo[coin].price}
                  address={walletInfo[coin].address}
                  network={walletInfo[coin].network}
                />
              </Grid>
            )
          }
        </Grid>
      </div>

      <NotificationContainer />

      {/* Deposit Modal */}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={depositModalStyle}>
          <h5>{modalData.code} Deposit Address</h5>
          <QRCode value={modalData.address} className="qrcode" />
          <hr />
          <h5 style={{ fontSize: '13px' }}>{modalData.address}  <span style={{ cursor: 'pointer' }} onClick={() => onClickCopy(modalData.address)}><ContentCopyIcon /></span></h5>
          <h6>Network: {modalData.network}</h6>
        </Box>
      </Modal>

      {/* Withdraw Modal */}
      <Modal
        open={showModal2}
        onClose={() => setShowModal2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={withdrawModalStyle}>
          <h4>Withdraw {modalData2.code}</h4>
          <TextField
            label="Network"
            defaultValue={modalData2.network}
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: '100%', marginBottom: '20px' }}
          />
          <TextField
            required
            label="Wallet Address"
            defaultValue=""
            sx={{ width: '100%', marginBottom: '20px' }}
            onChange={(e) => setModalData2({ ...modalData2, address: e.target.value })}
          />
          <TextField
            required
            label="Amount"
            type='number'
            defaultValue=""
            sx={{ width: '100%', marginBottom: '20px' }}
            onChange={(e) => setModalData2({ ...modalData2, amount: e.target.value })}
            error={Number(modalData2.amount) > Number(modalData2.balance) || Number(modalData2.amount) < Number(WITHDRAW_CONFIG[modalData2.code].minimum)}
            helperText={
              Number(modalData2.amount) > Number(modalData2.balance) ?
                `Insufficient Balance. Max: ${modalData2.balance}`
                : Number(modalData2.amount) < Number(WITHDRAW_CONFIG[modalData2.code].minimum) ?
                  `Insufficient Balance. Min: ${WITHDRAW_CONFIG[modalData2.code].minimum}` :
                  ''}
          />
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <span>Available Balance</span>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <span>{modalData2.balance} {modalData2.code}</span>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <span>Fees</span>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <span>{WITHDRAW_CONFIG[modalData2.code].fees} {modalData2.code}</span>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <span>Minimum Withdrawal</span>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <span>{WITHDRAW_CONFIG[modalData2.code].minimum} {modalData2.code}</span>
            </Grid>
          </Grid>
          <hr />
          <button className="btn buy" onClick={onClickWithdrawConfirm} disabled={Number(modalData2.amount) > Number(modalData2.balance) || Number(modalData2.amount) < Number(WITHDRAW_CONFIG[modalData2.code].minimum)}>Confirm</button>
        </Box>
      </Modal>
    </>

  );
}
