import React, { useState, useEffect, useContext } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { publicFetch } from '../util/fetcher';
import { coinslist } from '../config/config';

export default function Orders() {
  let userInfo = localStorage.getItem('userInfo');
  let username, user_id, token;
  if (userInfo) {
    username = JSON.parse(userInfo)?.username;
    user_id = JSON.parse(userInfo)?.id;
    token = localStorage.getItem('token');
  }

  const [orders, setOrders] = useState([])


  const getOrders = async () => {
    const { data } = await publicFetch.post('/order/get', { user_id });
    if (data.success)
      setOrders(data.data)
  };
  useEffect(() => {
    if (userInfo)
      getOrders()
  }, [userInfo])


  return (
    <>
      <div className="markets pb70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="markets-pair-list">
                <Tabs defaultActiveKey="all">
                  {/* new */}
                  <Tab eventKey="all" title="All">
                    <div className="table-responsive">
                      <table className="table star-active">
                        <thead>
                          <tr>
                            <th>Time</th>
                            <th>Pair</th>
                            <th>Type</th>
                            <th>Buy/Sell</th>
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order, index) =>
                            <>
                              <tr key={index}>
                                <td>{order.time}</td>
                                <td>
                                  {order.pair}
                                </td>
                                <td>{order.type}</td>
                                <td className={order.buysell == 'buy' ? "green" : "red"}>{order.buysell}</td>
                                <td>{order.price}</td>
                                <td>{order.coin_amount?.toFixed(3)}</td>
                                <td>{order.status}</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Tab>

                  {/* each coin */}
                  {
                    coinslist.map((coin, index) =>
                      <Tab eventKey={coin} title={coin} key={index}>
                        <div className="table-responsive">
                          <table className="table star-active">
                            <thead>
                              <tr>
                                <th>Time</th>
                                <th>Pair</th>
                                <th>Type</th>
                                <th>Buy/Sell</th>
                                <th>Price</th>
                                <th>Amount</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders.map((order, index) => {
                                if (order.pair == coin + '/USDT')
                                  return (
                                    <tr key={index}>
                                      <td>{order.time}</td>
                                      <td>
                                        <img src={`img/cryptos/${coin}.png`} alt="bitcoin" />  {order.pair}
                                      </td>
                                      <td>{order.type}</td>
                                      <td className={order.buysell == 'buy' ? "green" : "red"}>{order.buysell}</td>
                                      <td>{order.price}</td>
                                      <td>{order.coin_amount?.toFixed(3)}</td>
                                      <td>{order.status}</td>
                                    </tr>
                                  );
                              }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Tab>
                    )
                  }

                </Tabs>

                {/* <div className="text-center">
                  <a href="#!" className="load-more btn">
                    Load More <i className="icon ion-md-arrow-down"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
