import React, { useState, useEffect, useContext } from 'react'
import { Navbar, Nav, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ThemeConsumer } from '../context/ThemeContext';
import { AuthContext } from "../store/auth.js";

export default function Header() {

  let userInfo = localStorage.getItem('userInfo');
  let username, user_id, token, email, avatar;
  if (userInfo) {
    username = JSON.parse(userInfo)?.username;
    user_id = JSON.parse(userInfo)?.id;
    email = JSON.parse(userInfo)?.email;
    avatar = JSON.parse(userInfo)?.profilePhoto;
    token = localStorage.getItem('token');
  }

  const onClickLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('expiresAt');
    window.location.href = '/'
  };

  useEffect(() => {
    let el = document.querySelector('#darkTheme');
    if (el) {
      el.addEventListener('click', function () {
        document.body.classList.toggle('dark');
      });
    }
  }, [])

  return (
    <>
      <header className="light-bb">
        <Navbar expand="lg">
          <Link className="navbar-brand" to="/">
            <h3 className='logotext'>
              {
                username ? `${username.charAt(0).toUpperCase()
                  + username.slice(1)}'s Trading Account` : `Crypto Exchange`
              }
            </h3>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav mr-auto">
              <Link to="/markets" className="nav-link">
                Markets
              </Link>
              <Link to="/trade" className="nav-link">
                Trade
              </Link>
              <Link to="/swap/choose" className="nav-link">
                Swap
              </Link>
            </Nav>
            <Nav className="navbar-nav ml-auto">
              <Dropdown className="header-custom-icon">
                {/* theme button */}
                <ThemeConsumer>
                  {({ data, update }) => (
                    <Button variant="default" onClick={update} id="darkTheme">
                      {data.theme === 'light' ? (
                        <i className="icon ion-md-moon"></i>
                      ) : (
                        <i className="icon ion-md-sunny"></i>
                      )}
                    </Button>
                  )}
                </ThemeConsumer>

                {
                  userInfo && <>
                    {/* notification */}
                    <Dropdown.Toggle variant="default">
                      <i className="icon ion-md-notifications"></i>
                      <span className="circle-pulse"></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropdown-header d-flex align-items-center justify-content-between">
                        <p className="mb-0 font-weight-medium">
                          6 New Notifications
                        </p>
                        <a href="#!" className="text-muted">
                          Clear all
                        </a>
                      </div>
                      <div className="dropdown-body">
                        <a href="#!" className="dropdown-item">
                          <div className="icon">
                            <i className="icon ion-md-lock"></i>
                          </div>
                          <div className="content">
                            <p>Account password change</p>
                            <p className="sub-text text-muted">5 sec ago</p>
                          </div>
                        </a>
                        <a href="#!" className="dropdown-item">
                          <div className="icon">
                            <i className="icon ion-md-alert"></i>
                          </div>
                          <div className="content">
                            <p>Solve the security issue</p>
                            <p className="sub-text text-muted">10 min ago</p>
                          </div>
                        </a>
                        <a href="#!" className="dropdown-item">
                          <div className="icon">
                            <i className="icon ion-logo-android"></i>
                          </div>
                          <div className="content">
                            <p>Download android app</p>
                            <p className="sub-text text-muted">1 hrs ago</p>
                          </div>
                        </a>
                        <a href="#!" className="dropdown-item">
                          <div className="icon">
                            <i className="icon ion-logo-bitcoin"></i>
                          </div>
                          <div className="content">
                            <p>Bitcoin price is high now</p>
                            <p className="sub-text text-muted">2 hrs ago</p>
                          </div>
                        </a>
                        <a href="#!" className="dropdown-item">
                          <div className="icon">
                            <i className="icon ion-logo-usd"></i>
                          </div>
                          <div className="content">
                            <p>Payment completed</p>
                            <p className="sub-text text-muted">4 hrs ago</p>
                          </div>
                        </a>
                      </div>
                      <div className="dropdown-footer d-flex align-items-center justify-content-center">
                        <a href="#!">View all</a>
                      </div>
                    </Dropdown.Menu>
                  </>
                }
              </Dropdown>

              {userInfo ?
                <>
                  {/* User profile */}
                  <Dropdown className="header-img-icon">
                    <Dropdown.Toggle variant="default">
                      <img src={'img/avatar.svg'} alt="avatar" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="dropdown-header d-flex flex-column align-items-center">
                        <div className="figure mb-3">
                          <img src={`${process.env.REACT_APP_BACKEND_URL}/${avatar}`} alt="avatar" style={{ borderRadius: '50%' }} />
                        </div>
                        <div className="info text-center">
                          <p className="name font-weight-bold mb-0">{username}</p>
                          <p className="email text-muted mb-3">
                            {email}
                          </p>
                        </div>
                      </div>
                      <div className="dropdown-body">
                        <ul className="profile-nav">
                          <li className="nav-item">
                            <Link to="/account" className="nav-link">
                              <i className="mdi mdi-wallet"></i>
                              <span>Wallet</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/orders" className="nav-link">
                              <i className="mdi mdi-library-books"></i>
                              <span>Orders</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/profile" className="nav-link">
                              <i className="mdi mdi-account"></i>
                              <span>Profile</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/settings" className="nav-link">
                              <i className="mdi mdi-settings"></i>
                              <span>Settings</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/referral" className="nav-link">
                              <i className="mdi mdi-diamond"></i>
                              <span>Referral</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/api" className="nav-link">
                              <i className="mdi mdi-database"></i>
                              <span>API</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/" className="nav-link red" onClick={onClickLogout}>
                              <i className="mdi mdi-power"></i>
                              <span>Log Out</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
                :
                <>

                  {/* login/signup button */}
                  <Link className="nav-link home-signinbtn mr-3" to="/login">Sign In</Link>
                  <Link className="nav-link home-signupbtn" to="/signup">Sign Up</Link>
                </>}


            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  );

}
