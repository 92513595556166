import React, { useState, useEffect, useContext } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import RangeSlider from '../components/RangeSlider';
import { publicFetch } from '../util/fetcher';
import { NotificationContainer, NotificationManager } from 'react-notifications';



export default function MarketTrade({ coin, callbackOnExecuteOrder }) {
  const [balance, setBalance] = useState({});

  let userInfo = localStorage.getItem('userInfo');
  let username, user_id, token;
  if (userInfo) {
    username = JSON.parse(userInfo)?.username;
    user_id = JSON.parse(userInfo)?.id;
    token = localStorage.getItem('token');
  }

  const getBalance = async () => {
    const { data } = await publicFetch.post('get-balance', { username, token });
    setBalance(data?.balance)
  };


  useEffect(() => {
    getBalance();
  }, [coin])

  //input
  const [marketUSDT, setMarketUSDT] = useState(0);
  const [marketCoin, setMarketCoin] = useState(0);


  const onClickLimitBuy = () => {
    NotificationManager.warning('You can only place market order.', 'Please update pricing plan');
  }

  const onClickLimitSell = () => {
    NotificationManager.warning('You can only place market order.', 'Please update pricing plan');
  }


  const onClickMarketBuy = () => {
    if (!marketUSDT) { NotificationManager.warning('Input amount correctly'); return; }
    placeorder(user_id, coin, marketUSDT, 'buy');
  }

  const onClickMarketSell = () => {
    if (!marketCoin) { NotificationManager.warning('Input amount correctly'); return; }
    placeorder(user_id, coin, marketCoin, 'sell');
  }

  const placeorder = async (user_id, coin, amount, buysell) => {
    const { data } = await publicFetch.post('order/place', {
      user_id,
      coin,
      amount,
      buysell
    });
    if (data.success) {
      setBalance(data.data);
      if (buysell == 'buy')
        setMarketUSDT(0)
      else
        setMarketCoin(0)
      NotificationManager.success('Market ' + buysell + ' ' + coin + '/USDT', 'You have placed order successfully!');
      callbackOnExecuteOrder();
    }
    else
      NotificationManager.error(data.message, 'Error!');
  };

  return (
    <>
      <div className="market-trade">
        <Tabs defaultActiveKey="limit">
          {/* Limit */}
          <Tab eventKey="limit" title="Limit">
            <div className="d-flex justify-content-between">
              <div className="market-trade-buy">

                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Price"
                    required
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">USDT</span>
                  </div>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                    required
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">{coin}</span>
                  </div>
                </div>

                <ul className="market-trade-list">
                  <li>
                    <a >25%</a>
                  </li>
                  <li>
                    <a >50%</a>
                  </li>
                  <li>
                    <a >75%</a>
                  </li>
                  <li>
                    <a >100%</a>
                  </li>
                </ul>

                {/* <RangeSlider /> */}

                <p>
                  Available: <span>{balance.USDT?.toFixed(3)} USDT</span>
                </p>
                <p>
                  Volume: <span>0 BTC = 0 USD</span>
                </p>
                {userInfo ?
                  <button className="btn buy" onClick={onClickLimitBuy}>
                    Buy {coin}
                  </button>
                  :
                  <button className="btn buy" disabled>
                    Login or Signup
                  </button>
                }

              </div>
              <div className="market-trade-sell">

                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Price"
                    required
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">USDT</span>
                  </div>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                    required
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">{coin}</span>
                  </div>
                </div>
                {/* <RangeSlider /> */}
                <ul className="market-trade-list">
                  <li>
                    <a >25%</a>
                  </li>
                  <li>
                    <a >50%</a>
                  </li>
                  <li>
                    <a >75%</a>
                  </li>
                  <li>
                    <a >100%</a>
                  </li>
                </ul>
                <p>
                  Available: <span>{balance[coin]?.toFixed(3)} {coin}</span>
                </p>
                <p>
                  Volume: <span>0 BTC = 0 USD</span>
                </p>
                {userInfo ?
                  <button className="btn sell" onClick={onClickLimitSell}>Sell {coin}</button>
                  :
                  <button className="btn sell" disabled>
                    Login or Signup
                  </button>
                }


              </div>
            </div>
          </Tab>

          {/* Market */}
          <Tab eventKey="market" title="Market">
            <div className="d-flex justify-content-between">
              <div className="market-trade-buy">
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Best Market Price"
                    required
                    disabled
                  />
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                    required
                    value={marketUSDT}
                    onChange={(e) => setMarketUSDT(e.target.value ? Number(e.target.value) : 0)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">USDT</span>
                  </div>
                </div>
                <ul className="market-trade-list">
                  <li>
                    <a onClick={() => setMarketUSDT((Number(balance.USDT) / 4))}>25%</a>
                  </li>
                  <li>
                    <a onClick={() => setMarketUSDT((Number(balance.USDT) / 4 * 2))}>50%</a>
                  </li>
                  <li>
                    <a onClick={() => setMarketUSDT((Number(balance.USDT) / 4 * 3))}>75%</a>
                  </li>
                  <li>
                    <a onClick={() => setMarketUSDT(Number(balance.USDT))}>100%</a>
                  </li>
                </ul>
                <p>
                  Available: <span>{balance['USDT']?.toFixed(3)} USDT</span>
                </p>
                <p>
                  Volume: <span>{marketUSDT?.toFixed(3)} USDT</span>
                </p>
                {userInfo ?
                  <button className="btn buy" onClick={onClickMarketBuy}>
                    Buy
                  </button>
                  :
                  <button className="btn buy" disabled>
                    Login or Signup
                  </button>
                }
              </div>
              <div className="market-trade-sell">
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Best Market Price"
                    required
                    disabled
                  />
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                    required
                    value={marketCoin}
                    onChange={(e) => setMarketCoin(e.target.value ? Number(e.target.value) : 0)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">{coin}</span>
                  </div>
                </div>
                <ul className="market-trade-list">
                  <li>
                    <a onClick={() => setMarketCoin((Number(balance[coin]) / 4))}>25%</a>
                  </li>
                  <li>
                    <a onClick={() => setMarketCoin((Number(balance[coin]) / 4 * 2))}>50%</a>
                  </li>
                  <li>
                    <a onClick={() => setMarketCoin((Number(balance[coin]) / 4 * 3))}>75%</a>
                  </li>
                  <li>
                    <a onClick={() => setMarketCoin(Number(balance[coin]))}>100%</a>
                  </li>
                </ul>
                <p>
                  Available: <span>{balance[coin]?.toFixed(3)} {coin}</span>
                </p>
                <p>
                  Volume: <span>Actual volume may vary</span>
                </p>
                {userInfo ?
                  <button className="btn sell" onClick={onClickMarketSell}>Sell</button>
                  :
                  <button className="btn sell" disabled>
                    Login or Signup
                  </button>
                }
              </div>
            </div>
          </Tab>

          {/* Stop Orders */}
          {/* <Tab eventKey="stop-limit" title="Stop Limit">
            <div className="d-flex justify-content-between">
              <div className="market-trade-buy">
                <form action="#">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li>
                      <a >25%</a>
                    </li>
                    <li>
                      <a >50%</a>
                    </li>
                    <li>
                      <a >75%</a>
                    </li>
                    <li>
                      <a >100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button type="submit" className="btn buy">
                    Buy
                  </button>
                </form>
              </div>
              <div className="market-trade-sell">
                <form action="#">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li>
                      <a >25%</a>
                    </li>
                    <li>
                      <a >50%</a>
                    </li>
                    <li>
                      <a >75%</a>
                    </li>
                    <li>
                      <a >100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button className="btn sell">Sell</button>
                </form>
              </div>
            </div>
          </Tab>
          <Tab eventKey="stop-market" title="Stop Market">
            <div className="d-flex justify-content-between">
              <div className="market-trade-buy">
                <form action="#">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li>
                      <a >25%</a>
                    </li>
                    <li>
                      <a >50%</a>
                    </li>
                    <li>
                      <a >75%</a>
                    </li>
                    <li>
                      <a >100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button type="submit" className="btn buy">
                    Buy
                  </button>
                </form>
              </div>
              <div className="market-trade-sell">
                <form action="#">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">BTC</span>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">ETH</span>
                    </div>
                  </div>
                  <ul className="market-trade-list">
                    <li>
                      <a >25%</a>
                    </li>
                    <li>
                      <a >50%</a>
                    </li>
                    <li>
                      <a >75%</a>
                    </li>
                    <li>
                      <a >100%</a>
                    </li>
                  </ul>
                  <p>
                    Available: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Volume: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Margin: <span>0 BTC = 0 USD</span>
                  </p>
                  <p>
                    Fee: <span>0 BTC = 0 USD</span>
                  </p>
                  <button className="btn sell">Sell</button>
                </form>
              </div>
            </div>
          </Tab> */}
        </Tabs>
      </div>
      <NotificationContainer />
    </>
  );
}
