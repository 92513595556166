import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { publicFetch } from '../util/fetcher.js';
import { AuthContext } from '../store/auth.js';

export default function Login() {
  const { setAuthState, isAuthenticated } = useContext(AuthContext);
  const [emailErrStatus, setEmailErrStatus] = useState("");
  const [pwdErrStatus, setPwdErrStatus] = useState("");
  const [errStatus, setErrStatus] = useState("");

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  // Pull email, password from formData
  const { email, password } = formData;

  const authenticated = isAuthenticated();
  // console.log('Authent =>', authenticated);
  // const navigate = useNavigate();
  const history = useHistory();
  useEffect(() => {
    authenticated &&
      history.push("/account");
  }, [authenticated]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = (email, password) => {
    if (email === '') {
      setEmailErrStatus('Email is required.');
    } else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      setEmailErrStatus('Please input valid email.');
    } else {
      setEmailErrStatus('');
    }

    if (password === '') {
      setPwdErrStatus('Password is required.');
    } else {
      setPwdErrStatus('');
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    // login(email, password);
    console.log('email =>', email, 'password =>', password);
    validate(email, password);

    try {
      // @file: util/fetcher.js
      // const publicFetch = axios.create({baesURL: 'http://localhost:8000/api'})
      const { data } = await publicFetch.post('authenticate', { email, password })
      console.log('response =>', data.message);
      const { token, expiresAt, userInfo } = data
      setAuthState({ token, expiresAt, userInfo })
      // reset states
      setEmailErrStatus("");
      setPwdErrStatus("");
      setErrStatus("");
      // resetForm({})
      setFormData({ email: '', password: '' });
      // setIsComponentVisible(false)      
    } catch (error) {
      let errorMessage = error.response.data.message;
      errorMessage ? setErrStatus(JSON.parse(JSON.stringify(errorMessage))) : setErrStatus('');
      console.log('error =>', JSON.stringify(errorMessage), 'errStatus =>', errStatus);
    }
  }

  return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <form>
            <span>Sign In</span>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className='mb-3' style={{ color: '#fb6340' }}>{(emailErrStatus.length !== 0) && <small>*{emailErrStatus}</small>}</div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className='mb-3' style={{ color: '#fb6340' }}>{(pwdErrStatus.length !== 0) && <small>*{pwdErrStatus}</small>}</div>
            <div className='mb-3' style={{ color: '#fb6340' }}>{(errStatus || errStatus.length !== 0) && <small>{errStatus}</small>}</div>
            <div className="text-right">
              <Link to="/reset">Forgot Password?</Link>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="form-checkbox"
              />
              <label className="custom-control-label" htmlFor="form-checkbox">
                Remember me
              </label>
            </div>
            <button type="submit" className="btn btn-primary" onClick={(e) => { onSubmit(e) }}>
              Sign In
            </button>
          </form>
          <h2>
            Don't have an account? <Link to="/signup">Sign up here</Link>
          </h2>
        </div>
      </div>
    </>
  );
}
