export const coinslist = ['BTC', 'ETH', 'USDC', 'BNB', 'SHIB', 'YFI', 'DOGE'] //except USDT

export const WITHDRAW_CONFIG = {
    'BTC': {
        'fees': '0.00002-0.0005',
        'minimum': 0.0005,
    },
    'USDT': {
        'fees': '0.10-20.00',
        'minimum': 1.00,
    },
    'ETH': {
        'fees': '0.000001-0.01',
        'minimum': 0.0001,
    },
    'USDC': {
        'fees': '0.10-20.00',
        'minimum': 2.00,
    },
    'BNB': {
        'fees': '0.005',
        'minimum': 0.01,
    },
    'SHIB': {
        'fees': '20000.00-500000.00',
        'minimum': 50000.00,
    },
    'YFI': {
        'fees': '0.00003-0.0006',
        'minimum': 0.0005,
    },
    'DOGE': {
        'fees': '20.00',
        'minimum': 40.00,
    },
}