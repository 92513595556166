import React from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

export default function TradingChart({ theme, symbol }) {
  return (
    <>
      <div className="main-chart mb15">
        <TradingViewWidget
          symbol={symbol}
          // interval ='30m'
          theme={theme == 'light' ? Themes.LIGHT : Themes.DARK}
          locale="en"
          autosize
        />
      </div>
    </>
  );
}
