import React from 'react';
import Layout from '../components/Layout';
import { Switch, Route } from 'react-router-dom';
import Exchange from '../pages/exchange';
import Markets from '../pages/markets';
import Wallet from './wallet';
import Login from './login';
import Reset from './reset';
import OtpVerify from './otp-verify';
import OtpNumber from './otp-number';
import Lock from './lock';
import TermsAndConditions from './terms-and-conditions';
import NewsDetails from './news-details';
import Signup from './signup';
import Notfound from './notfound';
import Home from './home';
import Swap from './swap';
import Profile from './profile';
import Settings from './settings';
import Security from './security';
import Referral from './referral';
import Verification from './verification';
import Account from './account';
import Orders from '../components/Orders';
import API from './api';

export default function index() {
  return (
    <>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/trade">
            <Exchange />
          </Route>
          <Route path="/markets">
            <Markets />
          </Route>
          <Route path="/wallet">
            <Wallet />
          </Route>
          <Route path="/swap/choose">
            <Swap tab="choose" />
          </Route>
          <Route path="/swap/send">
            <Swap tab="send" />
          </Route>
          <Route path="/swap/receive">
            <Swap tab="receive" />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/security">
            <Security />
          </Route>
          <Route path="/referral">
            <Referral />
          </Route>
          <Route path="/verification">
            <Verification />
          </Route>
          <Route path="/api">
            <API />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/reset">
            <Reset />
          </Route>
          <Route path="/otp-verify">
            <OtpVerify />
          </Route>
          <Route path="/otp-number">
            <OtpNumber />
          </Route>
          <Route path="/lock">
            <Lock />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/news-details">
            <NewsDetails />
          </Route>
          <Route path="/notfound">
            <Notfound />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/orders">
            <Orders />
          </Route>
        </Switch>
      </Layout>
    </>
  );
}
