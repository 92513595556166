import axios from 'axios'

const baseURL =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_BACKEND_URL}/api`
    : `${process.env.SITE_URL}/api`

const publicFetch = axios.create({
  baseURL
})

export { publicFetch, baseURL }
