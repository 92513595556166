import React, { useState, useEffect, useContext } from 'react'
import HistoryOrder from '../components/HistoryOrder';
import MarketHistory from '../components/MarketHistory';
import MarketNews from '../components/MarketNews';
import MarketPairs from '../components/MarketPairs';
import MarketTrade from '../components/MarketTrade';
import OrderBook from '../components/OrderBook';
import TradingChart from '../components/TradingChart';
import TodayStats from '../components/TodayStats';
import TradingPairs from '../components/TradingPairs';
import { ThemeConsumer } from '../context/ThemeContext';
import { publicFetch } from '../util/fetcher';

export default function Exchange() {
  const [selectedCoin, setSelectedCoin] = useState('BTC')


  let userInfo = localStorage.getItem('userInfo');
  let username, user_id, token;
  if (userInfo) {
    username = JSON.parse(userInfo)?.username;
    user_id = JSON.parse(userInfo)?.id;
    token = localStorage.getItem('token');
  }


  const [orders, setOrders] = useState([])
  const getOrders = async () => {
    const { data } = await publicFetch.post('/order/get', { user_id, limit: 30 });
    if (data.success)
      setOrders(data.data)
  };
  useEffect(() => {
    if (userInfo)
      getOrders()
  }, [userInfo])

  return (
    <>
      <div className="container-fluid mtb15 no-fluid">
        <div className="row sm-gutters">
          {/* Row */}
          <div className="col-12">
            <TradingPairs onSelectPair={(coin) => { setSelectedCoin(coin) }} />
            {/* <TodayStats /> */}
          </div>

          {/* Row */}
          <div className="col-sm-12 col-md-3">
            <MarketPairs />
          </div>
          <div className="col-sm-12 col-md-6">
            <ThemeConsumer>
              {({ data }) => {
                return data.theme === 'light' ? (
                  <TradingChart theme='light' symbol={selectedCoin + 'USDT'} />
                ) : (
                  <TradingChart theme='dark' symbol={selectedCoin + 'USDT'} />
                );
              }}
            </ThemeConsumer>
            <MarketTrade coin={selectedCoin} callbackOnExecuteOrder={() => getOrders()} />
          </div>
          <div className="col-md-3">
            <OrderBook />
            <MarketHistory />
          </div>

          {/* Row */}
          <div className="col-md-3">
            <MarketNews />
          </div>
          <div className="col-md-9">
            <HistoryOrder orders={orders} />
          </div>
        </div>
      </div>
    </>
  );

}
