import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { ButtonToolbar, ButtonGroup, Button, Dropdown } from 'react-bootstrap'
import axios from 'axios';


export default function SwapBox() {
  const [coins, setCoins] = useState([])
  const [coin_send, setCoinSend] = useState('')
  const [coin_receive, setCoinReceive] = useState('')
  const [rate, setRate] = useState(0)
  const [rate_type, setRateType] = useState('dynamic')
  const [amount_send, setAmountSend] = useState(0.01)
  const [amount_receive, setAmountReceive] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [send_minimum, setSendMinimum] = useState(0)
  const [receive_maximum, setReceiveMaximum] = useState(0)
  const [search, setSearch] = useState('')

  const headers = {
    "Access-Control-Allow-Credentials": [
      "true"
    ],
    "Access-Control-Allow-Headers": [
      "Authorization"
    ],
    "Access-Control-Allow-Methods": [
      "GET",
      "POST"
    ],
    "Access-Control-Allow-Origin": [],
    "Access-Control-Expose-Headers": [
      "Location"
    ]
  }
  //mounted: get coins
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SWAP_API}/get_coins`, {}, { headers: headers })
      .then(res => {
        if (res.data.result) {
          var coins = res.data.data;
          setCoins(coins);
          setCoinSend(coins[0]['code']);
          setCoinReceive(coins[1]['code']);
          setLoaded(true);
        }
      })
  }, []);
  // Change rate
  useEffect(() => {
    if (coin_send && coin_receive) {
      setLoaded(false);
      axios.post(`${process.env.REACT_APP_SWAP_API}/get_full_rate`, { coin_send, coin_receive, type: rate_type })
        .then(res => {
          setRate(res.data.data);
          setLoaded(true);
        })
    }
  }, [coin_send, coin_receive, rate_type]);
  //Change amount_send
  useEffect(() => {
    setAmountReceive(Math.floor(amount_send * rate * 100000000) / 100000000)
  }, [amount_send, rate]);
  //Change min, max
  useEffect(() => {
    for (var x in coins) {
      if (coins[x]['code'] === coin_send) {
        setSendMinimum(Math.ceil(coins[x]['minimum'] * 100000) / 100000)
      }
      if (coins[x]['code'] === coin_receive) {
        setReceiveMaximum(Math.floor(coins[x]['maximum'] * 100000) / 100000)
      }
    }
  }, [coin_send, coin_receive, coins]);
  //Coin change button
  function changeCoin() {
    var temp = coin_send;
    setCoinSend(coin_receive);
    setCoinReceive(temp);
  }
  function goToChoosePage() {
    window.location.href = `/swap/choose?coin_send=${coin_send}&coin_receive=${coin_receive}&amount_send=${amount_send}`;
  }


  return (
    <>
      < div className="intro-form-exchange">
        <ButtonToolbar
          className="justify-content-between mb-4"
          aria-label="Toolbar with Button groups"
        >
          <ButtonGroup aria-label="Basic example">
            <Button className={rate_type === 'dynamic' ? "active" : ""} onClick={() => { setRateType('dynamic') }}>Dynamic Rate</Button>
            <Button className={rate_type === 'fixed' ? "active" : ""} onClick={() => { setRateType('fixed') }}>Fixed Rate</Button>
          </ButtonGroup>
        </ButtonToolbar>

        <div className="currency_validate">
          <div className="form-group">
            <label className="mr-sm-2">Send</label>
            <div className="input-group mb-3">
              <input type="number" className="form-control" step="0.00000001" value={amount_send} onChange={(e) => { setAmountSend(e.target.value) }} />
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic" >
                  <div style={{ minHeight: '25px' }}>
                    {coin_send ?
                      <>
                        <img src={`https://tradingpanelaccount.org/small/${coin_send}.png`} className="crypto-img mr-3" alt='' />{coin_send}
                      </> : ''}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <input type="text" className="form-control" placeholder="Search..." value={search} onChange={(e) => { setSearch(e.target.value) }}
                    onBlur={(e) => { setTimeout(function () { setSearch('') }, (500)) }} />
                  <div className="dropdown-menu-inner">
                    {
                      coins.filter(coin =>
                        coin.name.toLowerCase().includes(search.toLowerCase()) || coin.code.toLowerCase().includes(search.toLowerCase())
                      ).map((coin, i) =>
                        coin.code === coin_receive ?
                          <Dropdown.Item disabled key={i}>
                            <img src={`https://tradingpanelaccount.org/small/${coin.code}.png`} alt={coin.code} />
                            {coin.name} {coin.code}
                          </Dropdown.Item>
                          :
                          <Dropdown.Item onClick={() => { setCoinSend(coin.code) }} key={i}>
                            <img src={`https://tradingpanelaccount.org/small/${coin.code}.png`} alt={coin.code} />
                            {coin.name} {coin.code}
                          </Dropdown.Item>
                      )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {
              (send_minimum > amount_send) ?
                <p className="notify_limitamount">Minimum send amount: {send_minimum} {coin_send}</p> : <></>
            }
            <div className="exchange-rate-board d-flex justify-content-between mt-0 align-items-center">
              <p className="mb-0">
                <i className="fa fa-lock mr-3"></i>
                <span>1 {coin_send} ~ {rate} {coin_receive}</span>
              </p>
              <h6 className="mb-0 mr-1">
                <button className="btn" onClick={() => { changeCoin() }}>
                  <i className="fa fa-refresh"></i>
                </button>
              </h6>
            </div>
          </div>

          <div className="form-group">
            <label className="mr-sm-2">Receive</label>
            <div className="input-group mb-3">
              <input type="number" className="form-control" value={amount_receive} readOnly="readonly" />
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <div style={{ minHeight: '25px' }}>
                    {coin_receive ?
                      <>
                        <img src={`https://tradingpanelaccount.org/small/${coin_receive}.png`} className="crypto-img mr-3" alt='' />{coin_receive}
                      </> : ''}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <input type="text" className="form-control" placeholder="Search..." value={search} onChange={(e) => { setSearch(e.target.value) }}
                    onBlur={(e) => { setTimeout(function () { setSearch('') }, (500)) }} />
                  <div className="dropdown-menu-inner">
                    {coins.filter(coin =>
                      coin.name.toLowerCase().includes(search.toLowerCase()) || coin.code.toLowerCase().includes(search.toLowerCase())
                    ).map((coin, i) =>
                      coin.code === coin_send ?
                        <Dropdown.Item disabled key={i}>
                          <img src={`https://tradingpanelaccount.org/small/${coin.code}.png`} alt={coin.code} />
                          {coin.name} {coin.code}
                        </Dropdown.Item>
                        :
                        <Dropdown.Item onClick={() => { setCoinReceive(coin.code) }} key={i}>
                          <img src={`https://tradingpanelaccount.org/small/${coin.code}.png`} alt={coin.code} />
                          {coin.name} {coin.code}
                        </Dropdown.Item>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {
            (amount_receive > receive_maximum) ?
              <p className="notify_limitamount">Maximum receive amount: {receive_maximum} {coin_receive}</p> : <></>
          }
          {loaded ?
            rate ? <>
              <>
                <Button className="btn btn-success btn-block" onClick={() => { goToChoosePage() }}
                  disabled={amount_receive > receive_maximum || send_minimum > amount_send}>
                  Swap
                  <i className="la la-arrow-right"></i>
                </Button>
              </>
            </>
              :
              <>
                <div className='text-center'>
                  <h5>Can not trade this pair for now!</h5>
                </div>
              </>
            :
            <>
              <Link to={'/#'} className="btn btn-info btn-block">
                Loading
              </Link>
            </>}
        </div>
      </div>
    </>
  );
}
