import React, { useState, useEffect, useContext } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
export default function HistoryOrder({ orders }) {
  const [openOrders, setOpenOrders] = useState([])

  useEffect(() => {
    let data = orders.filter(order => order.status == 'open')
    setOpenOrders(data)
  }, [orders])


  return (
    <>
      <div className="history-order market-order mt15">
        <Tabs defaultActiveKey="key1">
          <Tab eventKey="key1" title="Order History">
            <div className="tablebox">
              <table className="table star-active">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Pair</th>
                    <th>Type</th>
                    <th>Buy/Sell</th>
                    <th>Price</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) =>
                    <>
                      <tr>
                        <td>{order.time}</td>
                        <td>
                          {order.pair}
                        </td>
                        <td>{order.type}</td>
                        <td className={order.buysell == 'buy' ? "green" : "red"}>{order.buysell}</td>
                        <td>{order.price}</td>
                        <td>{order.coin_amount?.toFixed(3)}</td>
                        <td>{order.status}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {!orders.length &&
              <span className="no-data">
                <i className="icon ion-md-document"></i>
                No data
              </span>
            }
          </Tab>
          <Tab eventKey="key2" title="Open Orders">
            <div className="tablebox">
              <table className="table star-active">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Pair</th>
                    <th>Type</th>
                    <th>Buy/Sell</th>
                    <th>Price</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {openOrders.map((order, index) =>
                    <>
                      <tr>
                        <td>{order.time}</td>
                        <td>
                          {order.pair}
                        </td>
                        <td>{order.type}</td>
                        <td className={order.buysell == 'buy' ? "green" : "red"}>{order.buysell}</td>
                        <td>{order.price}</td>
                        <td>{order.coin_amount?.toFixed(3)}</td>
                        <td>{order.status}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {!openOrders.length &&
              <span className="no-data">
                <i className="icon ion-md-document"></i>
                No data
              </span>
            }
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
