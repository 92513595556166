import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import axios from 'axios';

export default function Swap(props) {
  const [order_id, setOrderID] = useState('')
  const [coin_send, setCoinSend] = useState('')
  const [coin_receive, setCoinReceive] = useState('')
  const [amount_send, setAmountSend] = useState(0)
  const [amount_receive, setAmountReceive] = useState(0)
  const [network_fee, setNetworkFee] = useState(0)
  const [recipient, setRecipient] = useState('')
  const [server_address, setServerAddress] = useState('')
  const [expire_left, setExpireLeft] = useState('')
  const [status, setStatus] = useState(0)

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  //mounted:
  useEffect(() => {
    var order_id = query.get("order_id") || '0';
    setOrderID(order_id);
    getOrder(order_id);
    checkOrder(order_id);
  }, []);

  //go to receive page when status is 5
  useEffect(() => {
    if (status === 5) {
      window.location.href = "/swap/receive?order_id=" + order_id;
    }
  }, [status, order_id]);

  function getOrder(order_id) {
    axios.post(`${process.env.REACT_APP_SWAP_API}/get_order`, { order_id })
      .then(res => {
        if (res.data.result) {
          setCoinSend(res.data.data.coin_send);
          setCoinReceive(res.data.data.coin_receive);
          setAmountSend(Math.floor(res.data.data.amount_send * 100000000) / 100000000);
          setAmountReceive(Math.floor(res.data.data.amount_receive * 100000000) / 100000000);
          setNetworkFee(res.data.data.network_fee);
          setRecipient(res.data.data.recipient);
          setServerAddress(res.data.data.server_address);
          setExpireLeft(res.data.data.expire_left);
          setStatus(res.data.data.status);
        }
      })
  }
  function checkOrder(order_id) {
    axios.post(`${process.env.REACT_APP_SWAP_API}/check_order`, { order_id })
      .then(res => {
        if (res.data.result) {
          setCoinSend(res.data.data.coin_send);
          setCoinReceive(res.data.data.coin_receive);
          setAmountSend(Math.floor(res.data.data.amount_send * 100000000) / 100000000);
          setAmountReceive(Math.floor(res.data.data.amount_receive * 100000000) / 100000000);
          setNetworkFee(res.data.data.network_fee);
          setRecipient(res.data.data.recipient);
          setServerAddress(res.data.data.server_address);
          setExpireLeft(res.data.data.expire_left);
          setStatus(res.data.data.status);
        }
      })
    setInterval(() => { checkOrder(order_id) }, 30000)
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="swap-send">
            <h5 className="mb-3">TradeID: {order_id}</h5>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div>
                        <i>If the exact amount is not sent, it will not be automatiaclly detected and we have to manually process it</i>
                        <h4 className="mt-4">Send this amount:</h4>
                      </div>
                      <div>
                        <h4 className="my-4">{amount_send} {coin_send}</h4>
                      </div>
                      <div>
                        <h4>To this address:</h4>
                      </div>
                      <div>
                        <h4 className="my-3">{server_address}</h4>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2 col-lg-2">
                      {
                        status === 1 ?
                          <div>
                            <h4 className="mt-4">Time left:</h4>
                            <h6 className="mt-4">{expire_left}</h6>
                          </div>
                          :
                          <></>
                      }
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <QRCode value={server_address} className="qrcode" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div>
                  <h4>You Receive:</h4>
                </div>
              </div>
            </div>

            <div>
              <h4 className="mb-4">&#8776; {amount_receive} {coin_receive}</h4>
            </div>

            <div className="mb-3">
              <i>The rate locks after "confirm" stage. In most cases, only 1 confirmation is required</i>
            </div>

            <div className="pl-3">
              <h4>Network Fee:</h4>
              <div>
                <h4 className="my-3">{network_fee} {coin_receive}</h4>
              </div>
              <div>
                <i>This fee is deducted from the final amount you receive</i>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <h4 className="my-3">Recipient Wallet:</h4>
                <h4>&nbsp; {recipient}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <ul class="swap_progressbar_botton">
            <li className={status === 1 ? "active" : ""}>Deposit</li>
            <li className={status === 2 ? "active" : ""}>Confirm</li>
            <li className={status === 3 ? "active" : ""}>Swap</li>
            <li className={status === 4 ? "active" : ""}>Receive</li>
          </ul>
        </div>
      </div>
    </>
  );
}
