import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Row, Col } from 'react-bootstrap'
import Header from '../components/Header'
// import Testimonial from '../components/home/Testimonial'
import Bottom from '../components/home/Bottom'
import Footer from '../components/home/Footer'
import SwapBox from './swapbox';
import BtcChart from '../charts/btc'
import DashChart from '../charts/dash'
import EthChart from '../charts/eth'
import LtcChart from '../charts/ltc'
// import EosChart from '../charts/eos'
// import UsdChart from '../charts/usd'
// import XrpChart from '../charts/xrp'
// import XtzChart from '../charts/xtz'
import TradingChart from '../components/TradingChart';
import { ThemeConsumer } from '../context/ThemeContext';
// import Particles from 'react-particles-js';

function Home() {
  const particleparam = {
    "particles": {
      "number": {
        "value": 101
      },
      "color": {
        "value": "#5d5a72"
      },
      "size": {
        "value": 3
      },
      "line_linked": {
        "enable": true,
        "distance": 150,
        "color": "#c8c8c8",
        "opacity": 0.4,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 1,
      },
    },
    "interactivity": {
      "events": {
        "onhover": {
          "enable": true,
          "mode": "repulse"
        }
      }
    }
  }

  return (
    <>
      <Header />
      <div className="tradio">
        <div id="main-wrapper">
          <div className="particlearea">
            {/* <Particles className="particlebackground"
              params={particleparam} /> */}

            <div className="intro">
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-xl-5 col-lg-5 col-12">
                    <div className="intro-content">
                      <h1>Trade Crypto<br />with <strong className="text-primary">Trading Account</strong>.</h1>
                      <p>Fast and secure way to exchange<br />150+ cryptocurrencies</p>
                      <Form>
                        <Row>
                          <Col xs={6}>
                            <Form.Control type="email" placeholder="Email Address" size="lg" />
                          </Col>
                          <Col>
                            <Button variant="primary" type="submit" style={{ padding: "8px 20px" }}>
                              Sign Up
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-12">
                    <ThemeConsumer>
                      {({ data }) => {
                        return data.theme === 'light' ? (
                          <TradingChart theme='light' symbol='BTCUSDT' />
                        ) : (
                          <TradingChart theme='dark' symbol='BTCUSDT' />
                        );
                      }}
                    </ThemeConsumer>
                  </div>
                </div>
              </div>
            </div>

            <div className="swap">
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-xl-6 col-lg-6 col-12">
                    <SwapBox />
                  </div>
                  <div className="col-xl-5 col-lg-6 col-12">
                    <div className="intro-content">
                      <h1>No Registration<br />Instant Crypto Swaps</h1>
                      <p>Powered by <a href="https://Trading Account.org/" target="_blank" rel="noreferrer">Trading Account</a> </p>
                    </div>
                    <div className="mt-4 buttongroup">
                      <Link to={'#'} className="btn btn-primary my-1 waves-effect">
                        <img src='img/android.svg' alt="" />
                      </Link>
                      <Link to={'#'} className="btn btn-primary my-1 waves-effect">
                        <img src='img/apple.svg' alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="price-grid section-padding" id="price">
            <div className="container">
              <h2>Markets</h2>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="card">
                    <div className="card-header">
                      <div className="media">
                        <span><i className="cc BTC"></i></span>
                        <div className="media-body">
                          Bitcoin
                        </div>
                      </div>
                      <p className="mb-0"> 24h</p>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-5">
                          <h3>$62,548.22</h3>
                          <span className="text-success">+2.05%</span>
                        </div>
                        <div className="col-sm-7">
                          <div className="chartarea">
                            <BtcChart />
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <Link to={'/BTCUSDT'}>
                          <Button variant="primary">
                            Trade
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="card">
                    <div className="card-header">
                      <div className="media">
                        <span><i className="cc LTC"></i></span>
                        <div className="media-body">
                          Litecoin
                        </div>
                      </div>
                      <p className="mb-0"> 24h</p>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-5">
                          <h3>$1254.3</h3>
                          <span className="text-success">+1.05%</span>
                        </div>
                        <div className="col-sm-7">
                          <div className="chartarea">
                            <LtcChart />
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <Link to={'/LTCUSDT'}>
                          <Button variant="primary">
                            Trade
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="card">
                    <div className="card-header">
                      <div className="media">
                        <span><i className="cc DASH"></i></span>
                        <div className="media-body">
                          Dashcoin
                        </div>
                      </div>
                      <p className="mb-0"> 24h</p>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-5">
                          <h3>$184.70</h3>
                          <span className="text-success">+4.81%</span>
                        </div>
                        <div className="col-sm-7">
                          <div className="chartarea">
                            <DashChart />
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <Link to={'/DASHUSDT'}>
                          <Button variant="primary">
                            Trade
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="card">
                    <div className="card-header">
                      <div className="media">
                        <span><i className="cc ETH"></i></span>
                        <div className="media-body">
                          Ethereum
                        </div>
                      </div>
                      <p className="mb-0"> 24h</p>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-5">
                          <h3>$ 3628.35</h3>
                          <span className="text-success">+4.30%</span>
                        </div>
                        <div className="col-sm-7">
                          <div className="chartarea">
                            <EthChart />
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <Link to={'/ETHUSDT'}>
                          <Button variant="primary">
                            Trade
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio section-padding" id="portfolio">
            <div className="container">
              <div className="row py-lg-5">
                <div className="col-xl-7">
                  <div className="section-title">
                    <h2>Register to trade in just 2 minutes!</h2>
                    <p>Trading Account has a variety of features that make it the best place to start trading</p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-xl-7 col-lg-6">
                  <div className="portfolio_img">
                    <img src='img/portfolio.png' alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <div className="portfolio_list">
                    <div className="row">
                      <div className="media">
                        <span className="port-icon"> <i className="la la-leaf"></i></span>
                        <div className="media-body">
                          <h4>Safety</h4>
                          <p>98.5% of users funds are stored offline in cold storage
                          </p>
                        </div>
                      </div>
                      <div className="media">
                        <span className="port-icon"> <i className="la la-key"></i></span>
                        <div className="media-body">
                          <h4>Security</h4>
                          <p>Multiple levels of authentication such as email and 2FA is required for important processes like sign in and withdrawls
                          </p>
                        </div>
                      </div>
                      <div className="media">
                        <span className="port-icon"> <i className="la la-clock"></i></span>
                        <div className="media-body">
                          <h4>Ease of use</h4>
                          <p>
                            Simple user interface, start trading in just 2 minutes! If you prefer not to sign up, just use our instant swap features
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5>Available on:</h5>
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <a href='https://play.google.com/store/apps/details?id=com.Trading Account' className="portfolio_item">
                        <div className="available-item">
                          <span><i className="lab la-google-play"></i></span>
                          <h6>Google Play</h6>
                        </div>
                      </a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <a href='https://apps.apple.com/app/Trading Account/id1558998980#?platform=iphone' className="portfolio_item">
                        <div className="available-item">
                          <span><i className="la la-apple"></i></span>
                          <h6>App Store</h6>
                        </div>
                      </a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <a href='https://Trading Account.github.io/' className="portfolio_item">
                        <div className="available-item">
                          <span><i className="la la-plug"></i></span>
                          <h6>API</h6>
                        </div>
                      </a>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6">
                      <a href='https://www.myTrading Account.com/swap' className="portfolio_item">
                        <div className="available-item">
                          <span><i className="la la-globe"></i></span>
                          <h6>Web</h6>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="parallax">
            <div className="refers section-padding">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-12">
                    <div className="section-title text-center">
                      <h2>Refer a friend and your first <br />$5000 traded will be free.</h2>
                      <Button variant="primary" style={{ padding: "8px 20px" }}>
                        Sign Up
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Bottom />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Home;
