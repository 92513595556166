import React from 'react';
import Header from './Header';
import { useRouteMatch } from 'react-router-dom';

export default function Layout({ children }) {

  const matchHomepage = useRouteMatch('/')
  const homePage = matchHomepage && matchHomepage.isExact

  // const matchLogin = useRouteMatch('/login')
  // const loginPage = matchLogin && matchLogin.isExact

  // const matchSignup = useRouteMatch('/signup')
  // const signupPage = matchSignup && matchSignup.isExact

  // if (matchLogin || signupPage) {
  //   return (
  //     <>
  //       {children}
  //     </>
  // )}

  return (
    <>
      {!homePage && <Header />}
      {children}
    </>
  );
}
