import React, { useState, useEffect } from 'react'
import { } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import axios from 'axios';

export default function Swap(props) {
  const [order_id, setOrderID] = useState('')
  const [coin_send, setCoinSend] = useState('')
  const [coin_receive, setCoinReceive] = useState('')
  const [amount_send, setAmountSend] = useState(0)
  const [final_receive, setFinalReceive] = useState(0)
  const [final_rate, setFinalRate] = useState(0)
  const [recipient, setRecipient] = useState('')
  const [minutes_elapsed, setMinutesElapsed] = useState(0)

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  //mounted:
  useEffect(() => {
    var order_id = query.get("order_id") || '0';
    setOrderID(order_id);

    axios.post(`${process.env.REACT_APP_SWAP_API}/get_order`, { order_id })
      .then(res => {
        if (res.data.result) {
          setCoinSend(res.data.data.coin_send);
          setCoinReceive(res.data.data.coin_receive);
          setAmountSend(Math.floor(res.data.data.amount_send * 100000000) / 100000000);
          setFinalReceive(Math.floor(res.data.data.final_receive * 100000000) / 100000000);
          setFinalRate(Math.floor(res.data.data.final_rate * 100000000) / 100000000);
          setRecipient(res.data.data.recipient);
          setMinutesElapsed(calculateMinutesElapsed(res.data.data.created_at, res.data.data.updated_at));
        }
      })
  }, []);

  function calculateMinutesElapsed(created_at, updated_at) {
    var startTime = new Date(created_at);
    var endTime = new Date(updated_at);
    var difference = endTime.getTime() - startTime.getTime();
    var minutes = Math.round(difference / 60000);
    return minutes;
  }


  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="swap-receive">

            <h5 className="mb-3">TradeID: {order_id}</h5>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div>
                  <h5 className="mb-3">You sent:</h5>
                </div>
                <div>
                  <h5>&emsp;{amount_send} {coin_send}</h5>
                </div>
                <div>
                  <h5 className="my-3">You received:</h5>
                </div>
                <div>
                  <h5>&emsp;{final_receive} {coin_receive}</h5>
                </div>
                <div>
                  <h5 className="my-3">To the following address:</h5>
                </div>
                <div>
                  <h5>&emsp;{recipient}</h5>
                </div>
                <div>
                  <h5 className="my-3">Final Exchange rate :</h5>
                </div>
                <div>
                  <h5>&emsp;1 {coin_send} = {final_rate} {coin_receive}</h5>
                </div>

                <div className="mt-4" style={{ color: '#afaeae' }}>
                  <h6>This exchange took {minutes_elapsed} minutes</h6>
                </div>
              </div>
            </div>
            <div className="text-center">
              <img src="/img/congrats.gif" alt="Congratulations" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
