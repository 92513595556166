import React, { useState, useContext } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';

import { publicFetch } from '../util/fetcher.js';
import { AuthContext } from '../store/auth.js';

function Signup() {
  const { setAuthState } = useContext(AuthContext);

  const [userErrStatus, setUserErrStatus] = useState("");
  const [emailErrStatus, setEmailErrStatus] = useState("");
  const [pwdErrStatus, setPwdErrStatus] = useState("");
  const [errStatus, setErrStatus] = useState("");

  const [formData, setFormData] = useState({
    username: '', email: '', password: '', passwordConfirmation: ''
  });
  // Pull name, email, password from formData
  const { username, email, password, passwordConfirmation } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const validate = (username, email, password, passwordConfirmation) => {
    if (username === '') {
      setUserErrStatus('Username is required.');
    } else if (username.length > 16) {
      setUserErrStatus('Username is too long, must be at most 16 characters long.');
    } else {
      setUserErrStatus('');
    }

    if (email === '') {
      setEmailErrStatus('Email is required.');
    } else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      setEmailErrStatus('Please input valid email.');
    } else {
      setEmailErrStatus('');
    }

    if (password === '') {
      setPwdErrStatus('Password is required.');
    } else if (password.length < 6) {
      setPwdErrStatus('Password must be at least 6 characters long.');
    } else if (password.length > 50) {
      setPwdErrStatus('Password must be at most 50 characters long.');
    } else {
      setPwdErrStatus('');
    }

    if (passwordConfirmation === '') {
      setPwdErrStatus('Please confirm your password');
    }

    if (password !== passwordConfirmation) {
      setPwdErrStatus('Passwords must match');
    }
  }

  const history = useHistory();
  const onSubmit = async (e) => {
    console.log('email =>', email, 'password =>', password);
    validate(username, email, password, passwordConfirmation);
    try {
      const { data } = await publicFetch.post('signup', { username, email, password, passwordConfirmation });
      console.log(data);
      const { token, expiresAt, userInfo } = data;
      setAuthState({ token, expiresAt, userInfo });
      // reset states
      setUserErrStatus("");
      setEmailErrStatus("");
      setPwdErrStatus("");
      setErrStatus("");
      // resetForm({})
      setFormData({ username: '', email: '', password: '', passwordConfirmation: '' });
      // redirect to login
      history.push('/login');

    } catch (error) {
      let errorMessage = error.response.data.message;
      errorMessage ? setErrStatus(JSON.parse(JSON.stringify(errorMessage))) : setErrStatus('');
      console.log('error =>', JSON.stringify(errorMessage), 'errStatus =>', errStatus);
    }
  };

  return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <span>Create Account</span>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Username"
              name="username"
              value={username}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div className='mb-3' style={{ color: '#fb6340' }}>{(userErrStatus.length !== 0) && <small>*{userErrStatus}</small>}</div>

          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div className='mb-3' style={{ color: '#fb6340' }}>{(emailErrStatus.length !== 0) && <small>*{emailErrStatus}</small>}</div>

          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div className='mb-3' style={{ color: '#fb6340' }}>{(pwdErrStatus.length !== 0) && <small>*{pwdErrStatus}</small>}</div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Confirm Password"
              name="passwordConfirmation"
              value={passwordConfirmation}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div className='mb-3' style={{ color: '#fb6340' }}>{(errStatus || errStatus.length !== 0) && <small>{errStatus}</small>}</div>
          <button className="btn btn-primary" onClick={(e) => { onSubmit(e) }}>
            Create Account
          </button>
          <h2>
            Already have an account?
            <Link to="/login"> Sign in here</Link>
          </h2>
        </div>
      </div>
    </>
  );
}
export default Signup;